import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '~/components/global/Layout';

import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import Pagination from '../global/Pagination';
import { SeoContainer } from '../global/SeoContainer';
import { ResourceGridInner } from '../resources/ResourceGrid';

export default function categoryPage({
    data: { wpPage, wp, allWpCategory, allWpPost, wpCategory },
    pageContext,
}: {
    data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
    console.log(allWpPost)
    return (
        <Layout wp={wp}>
            <SeoContainer
                post={wpPage}
                title="Ablestar Case Studies"
            />
            <div className="relative p-2 mx-auto md:px-5 md:py-4 ">
                <div className="rounded-md default-blue-gradient">
                    <div className="noisy">
                        <div className="container relative flex items-center pt-[6rem] pb-8 md:pt-[10rem] md:pb-[9.5rem]">
                            <div className="space-y-[8px]">
                                <h6 className="text-h6">Case Studies</h6>
                                <h1 className="text-h1">How brands use Ablestar</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pb-[5rem] md:pb-[9rem]">
                <div className="mt-[29px] md:mt-[84px]">
                    <ResourceGridInner posts={allWpPost?.nodes} previewElement="CaseStudyPreview" />
                    <Pagination {...pageContext} allWpCategory={allWpCategory} />
                </div>
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
  query Category($id: String!, $skip: Int!, $limit: Int!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }

    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        date
        dateGmt
        title
        slug
        uri
        databaseId
        id
        casestudy {
            company
            industry
            subtitle
            squareLogo  { ...Image }
        }
        author {
          node {
            name
          }
        }
        excerpt
        postFlexibleContent {
          masthead {
            image {
              ...Image
            }
            description
          }
        }
        featuredImage {
          node {
            ...Image
          }
        }
      }
    }
    allWpCategory(
      filter: { posts: { nodes: { elemMatch: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } } } } }
      sort: { fields: posts___nodes___date, order: DESC }
    ) {
      nodes {
        uri
        name

        id
        # categoryPage {
        #   categoryPageSubheading
        #   categoryPageHeading
        #   allCategoriesHeading
        # }
      }
    }
    wpCategory(id: { eq: $id }) {
      ...GeneratedWpCategory
    }
  }
`;
